import * as actionTypes from './actions';

export const initialState = {
    isLoader: false
};
const loaderReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case actionTypes.LOADER_START:
            return {
                ...state,
                isLoader: true
            };
        case actionTypes.LOADER_STOP:
            return {
                ...state,
                isLoader: false
            };
        default:
            return state;
    }
};
export default loaderReducer;
