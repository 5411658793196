import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { warning } from './common';

const isDistributorPayment = () => {
	const ispayment = localStorage.getItem('subscriptionStatus');

	if (typeof window !== 'undefined') {
		return ispayment == 'paid' ? true : false;
	}
	// return false;
};

function withUser(Child) {
	return (props) => {
		const histrory = useHistory();

		useEffect(() => {
			if (isDistributorPayment()) {
				// console.log(18, isDistributorPayment());
			} else {
				histrory.push('/subscription');
				warning('Please Purchase a Subscription');
				// console.log(21, isDistributorPayment());
			}
		}, []);

		return <Child {...props}></Child>;
	};
}

export default withUser;
