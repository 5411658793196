import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App/index';
import * as serviceWorker from './serviceWorker';
import { store } from './store/store';
import config from './config';
import './assets/scss/style.scss';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import CartProvider from './CartContext/CartProvider';

const app = (
	<React.StrictMode>
		<Provider store={store}>
			<CartProvider>
				<BrowserRouter basename={config.basename}>
					<App />
					<ToastContainer
						position="bottom-right"
						autoClose={3000}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						pauseOnHover
					/>
				</BrowserRouter>
			</CartProvider>
		</Provider>
	</React.StrictMode>
);
ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
