import { combineReducers } from 'redux';
import { createSelectorHook } from 'react-redux';
import ableReducer from './ableReducer';
import loaderReducer from './loaderReducer';
import userReducer from './userReducer';

const reducer = combineReducers({
    able: ableReducer,
    loader: loaderReducer,
    user: userReducer
});
export const useSelector = createSelectorHook();
export default reducer;
