import React from 'react';
import './XiFiSpinner.scss';

const XiFiSpinner = () => {
	return (
		// {/* <div class="loadingio-spinner-radio-lbggy8icst">
		// 	<div class="ldio-y1c27vmaflb">
		// 		<div></div>
		// 		<div></div>
		// 		<div></div>
		// 	</div>
		// </div> */}

		<div className="loadingio-spinner-ripple-v4fcb5cmrqf">
			<div class="ldio-81m1rybzdo">
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

export default XiFiSpinner;
