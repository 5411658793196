import axios from 'axios';
import config from '../config';

import { toast } from 'material-react-toastify';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const BASE_URL = config.BASE_URL;

export const apiCall = async (method, path, body, params, headers) => {
	let token = localStorage.getItem('auth');

	let req = {
		method: method,
		data: body,
		url: BASE_URL + path,
		params: params ? params : {}
	};

	if (headers) req.headers = headers;

	if (token) {
		req.headers = {
			Authorization: token
		};
	}
	let response = await axios(req);

	return response.data || {};
};

export const successLog = async (msg, data) => {
	toast.success(msg);
};

export const errorLog = async (msg, data) => {
	toast.error(msg);
};

export const infoLog = async (msg, data) => {
	toast.info(msg);
};

export const warning = async (msg, data) => {
	toast.warning(msg);
};

export const confirmBox = async (title, message) => {
	return new Promise((resolve, reject) => {
		let obj = {
			title: title,
			text: message,
			showCancelButton: true,
			cancelButtonText: 'No',
			confirmButtonText: `Yes`
		};

		Swal.fire(obj).then((result) => {
			if (result.isConfirmed) {
				resolve(1);
			} else {
				resolve(0);
			}
		});
	});
};
